import { makeStyles } from "@material-ui/core";
import { Box, Button, ButtonGroup } from "@mui/material";
import React from "react";
import CommonTable from "../../components/commonTable";

const useStyles = makeStyles((theme) => ({
  optionButton: {
    color: "#5EBDCC",
    borderColor: "#5EBDCC",
    "&.Mui-selected": {
      backgroundColor: "#5EBDCC",
      color: "#fff",
    },
  },
}));
const WorkIssueLevel = ({
  setIssueType,
  issueType,
  columnList,
  columnData,
  IssuesList,
}) => {
  const classes = useStyles();

  const typeHandler = (type) => {
    setIssueType(type);
  };
  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        m={2}
      >
        <h5>Issue Level</h5>
        <div>
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            size="small"
          >
            {IssuesList?.map((item, index) => (
              <Button
                variant={issueType === item?.value ? "contained" : "outlined"}
                className={classes.optionButton}
                onClick={() => typeHandler(item?.value)}
                key={index}
              >
                {item?.value}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </Box>
      <CommonTable columns={columnList} data={columnData} />
    </div>
  );
};

export default WorkIssueLevel;
