import React, { useMemo } from "react";
import {
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom";
import BreadCrumb from "../../../components/breadCrumb/BreadCrumb";
import { Box, Button, Card, Paper, Tab, Tabs } from "@material-ui/core";
import Template from "../../Project/pageLayout";
import { GlobalData } from "../../../context/globalData";
import { useContext } from "react";
import { Metric, iterationMap } from "../../../utils/textMap";
import { useState, useEffect } from "react";
import {
  MODULE_API_URL_GEN,
  addLeadingZeroToDateRange,
  datePayload,
  getSelectedDate,
} from "../../../utils/moduleTabApi";
import axios from "../../../utils/axiosHelper";
import PointerWrapper from "../../../components/overview/pointerWrapper";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { riskMap } from "../../../utils/levelMap";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import Header from "../Header";
import Graph from "../Graph";
import InvestmentProfile from "./investmentProfile";
import { fix } from "../../../utils/changeDecimal";
import EpicDropDown from "../../../components/epicDropDown";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import SelectField from "../../../components/select/selectField";
import RangeSelector from "../../../components/rangeSelector";
import NormalSelect from "../../../components/select/normalSelect";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import EffortAllocation from "./effortAllocation";
import { FormControlLabel, Grid, Switch, Tooltip } from "@mui/material";
import GraphCard from "../../Project/projectDashboard/graphCard";
import WorkAlocationChart from "../../Project/projectDashboard/graphs/workAlocationChart";
import { getMetricRange } from "../../../utils/getMetricRange";
import { config } from "../../../Config/permission";
import Trend from "../../Project/projectDashboard/trend";
import IssueLevel from "../../Project/projectDashboard/IssueLevel";

const useStyles = makeStyles((theme) => ({
  anchor: {
    cursor: "pointer",
    color: "#5EBDCC",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const SingleResoureceMetricInfo = () => {
  const { state } = useLocation();
  const history = useHistory();
  const {
    iterationValueGlobal,
    projectRangePickerValue: rangePickerValue,
    setProjectRangePickerValue: setRangePickerValue,
    setIsDefinaionPopup,
    setDefinationKPI,
  } = useContext(GlobalData);
  const sprintDate = state.date;
  const [metricValue, setMetricValue] = useState("Metric Details");
  const [singleResourceInfo, setSingleResourceInfo] = useState();
  const [materialTableColums, setMaterialTableColums] = useState([]);
  const [kpiName, setKpiName] = useState();
  const [headerData, setHeaderData] = useState({});
  const { projectId, resourceId } = useParams();
  const classes = useStyles();
  const [allMetrics, setAllMetric] = useState([]);
  const [resourceHealth, setResourceHealth] = useState();
  const [resourceProcess, setResourceProcess] = useState();
  const [resourceQuality, setResourceQuality] = useState();
  const [resourceTrendName, setResourceTrendName] = useState();
  const [prMergeCount, setPrMergeCount] = useState({});
  const [epicData, setEpicData] = useState({ url: "", selectedEpic: [] });
  const [openRange, setOpenRange] = useState(false);
  const [range, setRange] = useState([]);
  const [iteration, setIteration] = useState(sprintDate ? 14 : 30);
  const [statuslist, setStatusList] = useState();
  const [selectedSubStatus, setSelectedSubStatus] = useState();
  const [avgStoryPoint, setAvgStoryPoint] = useState();
  const [showHideTrendButton, setShowHideTrendButton] = useState(true);
  const [date, setDate] = useState(sprintDate || rangePickerValue);
  const [singleResourceFlowEfficiency, setSingleResourceFlowEfficiency] =
    useState();
  const [childViewAll, setChildViewAll] = useState();

  const tableOption = {
    sorting: false,
    search: false,
    toolbar: false,
    draggable: false,
    paging: false,
    fixedColumns: {
      left: 2,
      right: 0,
    },
    headerStyle: {
      backgroundColor: "#efefef",
    },
  };

  const trendAndIssue = [
    "Resource Commit Without Ticket Reference",
    "Resource Commit with pull request",
    "Resource WIP Average Age",
    "Resource Unreviewed PR",
  ];
  const resourceApiHandler = (url) => {
    axios
      .get(url)
      .then((result) => {
        createData(result?.data);
        setAllMetric(
          result?.data?.metrics?.map((e) => {
            return {
              value: e?.Id,
              lable: e?.name,
            };
          })
        );
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const createData = (e) => {
    const data = [];
    const columnData = {
      ResourceName: e?.ProjectName,
      TeamName: e?.TeamName,
      Id: e?.ProjectId,
      RiskLevel: e?.riskLevel,
    };
    // Add metric names to the columnData object
    e.metrics?.forEach((item) => {
      const targetFrom = item?.risks?.find((t) => t?.RiskLevel == 1);
      columnData[item?.name] = {
        name: item?.Value,
        risk: item?.RiskLevel,
        id: item?.Id,
        from: targetFrom?.From,
        to: targetFrom?.To,
      };
    });
    data.push(columnData);
    setSingleResourceInfo(data);
  };

  // call summary and detail api
  useEffect(() => {
    if (!projectId) return;
    let Module_URL;
    if (state.startDate && state.endDate) {
      Module_URL = `no_of_days=14&startDay=${state.startDate}&endDay=${state.endDate}&iteration=14&project_id=${projectId}`;
    } else {
      Module_URL = MODULE_API_URL_GEN(
        rangePickerValue,
        projectId,
        iterationMap[iterationValueGlobal]
      );
    }
    let url;
    url = `/api/v2/resource/getMetrics/${resourceId}?${Module_URL}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    resourceApiHandler(url);
  }, [
    rangePickerValue,
    projectId,
    iterationValueGlobal,
    metricValue,
    state,
    epicData,
  ]);

  const definationPopupHandler = (value) => {
    setKpiName(value);
    setIsDefinaionPopup(true);
  };

  // const fetch header Detail
  const fetchHeaderDetail = () => {
    // to fetch planning accuracy
    const dateUrl = datePayload(date);
    let url = "";
    if (epicData.selectedEpic.length > 0) {
      url = `/api/v2/resource/taskcomplete${epicData?.url}&resourceName=${state?.resourceName}&iteration=${iteration}&${dateUrl}`;
    } else {
      url = `/api/v2/resource/taskcomplete?resourceName=${state?.resourceName}&iteration=${iteration}&${dateUrl}`;
    }
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const result = res.data;
        setHeaderData({
          storyCompleted: result?.AvgCompleted,
          // capacityAccuracy: result?.percentageCompleted,
          churn: result?.codechurn,
          commitwoticket: result?.commitwoticket,
          commitReferenceRisk: result?.Ris,
          legacyRefactor: result?.legacyRefactor,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const fetchResourceHealth = () => {
    // to fetch planning accuracy
    const dateUrl = datePayload(date);
    let url = `/api/v2/resource/resourcehealth?projectId=${projectId}&resourceName=${state?.resourceName}&${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const result = res.data;
        setResourceHealth(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    let url = `/api/v2/resource/avgTaskCompleted/sprint?projectId=${projectId}&resourceId=${resourceId}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        setAvgStoryPoint(res.data?.avgCompletedSprint);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [projectId, resourceId]);

  const fetchResourceQuality = () => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/quality/get/resource/overall/${projectId}?resourceName=${state?.resourceName}&${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const result = res.data[0];
        setResourceQuality(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const fetchResourceProcess = () => {
    // to fetch planning accuracy
    const dateUrl = datePayload(date);
    let url = `/api/v2/ph/processhealth/resource/${projectId}?resourceName=${state?.resourceName}&${dateUrl}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        const result = res.data[0];
        setResourceProcess(result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getPrMergeData = () => {
    const dateUrl = datePayload(date);
    axios
      .get(
        `/api/v2/resource/prmergedwithreview?resourceName=${
          state?.resourceName
        }${epicData.url.replace(/^\?/, "&")}&iteration=${iteration}&${dateUrl}`
      )
      .then((res) => {
        if (res.data) {
          let data = res?.data;
          setPrMergeCount({
            ...data,
            firstTimePassPercentage: (
              (res?.data?.FirstTime / res?.data?.CompletedIssue) *
              100
            )?.toFixed(2),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const epicDropDownHandler = (url, list) => {
    setEpicData({ url: url.trim(), selectedEpic: list });
  };

  useEffect(() => {
    fetchHeaderDetail();
    getPrMergeData();
    fetchResourceProcess();
    fetchResourceQuality();
    fetchResourceHealth();
  }, [epicData.url, date, iteration, state, epicData]);

  const getUnits = (name) => {
    switch (name) {
      case "Total Work Delivered (Story, Task, Bug) (Count)":
      case "Stories Completed (Count)":
      case "PR Merge Frequency (Count)":
      case "Commits (Count)":
      case "Defect in Backlog (Count)":
      case "Defect in Progress (Count)":
        return "(Count)";
      case "Avg PR Resolution Time (Days) ":
      case "Defect Lead Time (Days) ":
        return "(Days)";
      case "PR Size (Code changes)":
        return "(Code Changes)";
      case "Commit Size (Line of Code)":
        return "(Line Of Code)";
      default:
        return "(%)";
    }
  };

  // create column list
  useEffect(() => {
    if (!singleResourceInfo) return;
    const columns = [
      {
        field: "ResourceName",
        title: "Contributor Name",
        width: "150px",
        render: (rowData) => (
          <div>
            <span>{rowData?.ResourceName}</span>
          </div>
        ),
      },
      {
        field: "TeamName",
        title: "Team Name",
        width: "150px",
      },
    ];

    Object.keys(singleResourceInfo[0])?.forEach((item) => {
      if (config.resource.singleResourceTableColum) {
        if (
          item !== "ResourceName" &&
          item !== "ResourceName" &&
          item !== "TeamName" &&
          item !== "tableData" &&
          item !== "Id" &&
          item !== "RiskLevel"
        ) {
          columns.push({
            title: (
              <HeaderTitle
                lablel={item}
                definationPopupHandler={definationPopupHandler}
              />
            ),
            field: item,
            width: "150px",
            render: (rowData) => (
              <Tooltip
                title={
                  <h6>
                    Target:{" "}
                    {rowData[item]?.from !== null &&
                    rowData[item]?.from !== undefined
                      ? `${rowData[item]?.from}${getUnits(item)}`
                      : " - "}
                    {" to "}
                    {rowData[item]?.to !== null &&
                    rowData[item]?.to !== undefined
                      ? `${rowData[item]?.to}${getUnits(item)}`
                      : " - "}
                  </h6>
                }
              >
                <div>
                  {/* <PointerWrapper val={riskMap[rowData[item]?.risk]}> */}
                  {fix(rowData[item]?.name)}&nbsp;
                  <ChevronRightIcon />
                  {/* </PointerWrapper> */}
                </div>
              </Tooltip>
            ),
          });
        }
      } else {
        if (
          item !== "ResourceName" &&
          item !== "ResourceName" &&
          item !== "TeamName" &&
          item !== "tableData" &&
          item !== "Id" &&
          item !== "RiskLevel" &&
          item !== "PR Size" &&
          item !== "PR Merge Frequency" &&
          item !== "Commits" &&
          item !== "Commits Size" &&
          item !== "Avg PR Resolution Time"
        ) {
          columns.push({
            title: (
              <HeaderTitle
                lablel={item}
                definationPopupHandler={definationPopupHandler}
              />
            ),
            field: item,
            width: "150px",
            render: (rowData) => (
              <Tooltip
                title={
                  <h6>
                    Target:{" "}
                    {rowData[item]?.from !== null &&
                    rowData[item]?.from !== undefined
                      ? `${rowData[item]?.from}%`
                      : " - "}
                    {" to "}
                    {rowData[item]?.to !== null &&
                    rowData[item]?.to !== undefined
                      ? `${rowData[item]?.to}%`
                      : " - "}
                  </h6>
                }
              >
                <div>
                  <PointerWrapper val={riskMap[rowData[item]?.risk]}>
                    {fix(rowData[item]?.name)}&nbsp;
                    <ChevronRightIcon />
                  </PointerWrapper>
                </div>
              </Tooltip>
            ),
          });
        }
      }
    });
    setMaterialTableColums(columns);
  }, [singleResourceInfo]);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios
      .get(`/api/risks?ProjectId=${projectId}`)
      .then((result) => {
        setRange(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId]);

  // flow Efficiency api
  useEffect(() => {
    const dateRange = datePayload(date);
    const dateUrl = addLeadingZeroToDateRange(dateRange);
    let url = `/api/v2/teams/flowEfficiency/${projectId}?${dateUrl}&resourceName=${state?.resourceName}`;
    if (epicData?.url) {
      url += `&${epicData?.url.substring(1)}`;
    }
    axios
      .get(url)
      .then((res) => {
        setSingleResourceFlowEfficiency(res?.data?.Efficiency);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [date, epicData]);

  const resourceTrend = (data) => {
    setResourceTrendName(data?.data);
    setStatusList(data?.list);
    setSelectedSubStatus(data?.status);
  };

  const capacityNumber = parseFloat(prMergeCount?.PerCompletedSP);
  const CommitwithpullrequestNumber = parseFloat(prMergeCount?.commitPer);
  const UnreviewedPRNumber = parseFloat(prMergeCount?.PRPercent);

  const capcityAcc = useMemo(
    () => getMetricRange(range, capacityNumber, "Capacity Accuracy"),
    [capacityNumber, range]
  );
  const UnreviewedPR = useMemo(
    () => getMetricRange(range, UnreviewedPRNumber, "Unreviwed PR"),
    [UnreviewedPRNumber, range]
  );

  const CodeChurn = useMemo(
    () => getMetricRange(range, headerData?.churn, "Code Churn"),
    [headerData?.churn, range]
  );

  const Commitwithpullrequest = useMemo(
    () => getMetricRange(range, CommitwithpullrequestNumber, "Commit With PR"),
    [CommitwithpullrequestNumber, range]
  );
  const FlowEfficiencyRisk = useMemo(
    () =>
      getMetricRange(
        range,
        singleResourceFlowEfficiency,
        "Average Flow Efficiency"
      ),
    [singleResourceFlowEfficiency, range]
  );
  const commitWithoutTicketRef = useMemo(() => {
    if (!headerData?.commitwoticket) return;
    const val = headerData?.commitwoticket.match(/\d+\.\d+/);
    const number = val?.length > 0 ? +val[0] : 0;
    return getMetricRange(
      range,
      number.toFixed(2),
      "Commit Without Ticket Ref"
    );
  }, [headerData?.commitwoticket, range]);

  const getComponent = (key) => {
    switch (key) {
      case 0:
        return (
          <>
            <Header
              capacityAccuracy={prMergeCount?.PerCompletedSP}
              codeChurn={headerData?.churn}
              resourceHealth={resourceHealth}
              commitReference={headerData?.commitwoticket}
              // commitReferenceRisk={headerData?.commitReferenceRisk}
              CommitWithoutPullRequestCount={`${prMergeCount?.PRWithoutReview}/${prMergeCount?.commitPr} (${prMergeCount?.PRPercent} %)`}
              // CommitWithoutPullRequestCountRisk={prMergeCount?.PRPercent}
              commitWithPr={`${prMergeCount?.CommitWithPR}/${prMergeCount?.CommitCount} (${prMergeCount?.commitPer} %)`}
              // CommitWithoutTicketReferenceRisk={prMergeCount?.commitPer}
              PrComments={`${prMergeCount?.commitPr}/${prMergeCount?.SumComment}`}
              // PrCommentsRisk={prMergeCount.RiskPRCommentDepth}
              prReviewTime={`${prMergeCount.PrReviewTime} Days`}
              firstTimePass={`${prMergeCount?.FirstTime} / ${prMergeCount?.CompletedIssue} (${prMergeCount?.firstTimePassPercentage} %)`}
              // firstTimePassRisk={prMergeCount?.firstTimePassPercentage}
              mostReviewed={prMergeCount?.MostReviewed}
              commitPr={prMergeCount?.commitPr}
              resourceProcess={resourceProcess}
              resourceQuality={resourceQuality}
              date={date}
              StoryPointCount={{
                AssignedStoryPoint: prMergeCount?.AssignedStoryPoint,
                CompletedStoryPoint: prMergeCount?.CompletedStoryPoint,
              }}
              riskAndRange={{
                capacityAccuracyData: {
                  risk: capcityAcc?.RiskCategory,
                  from: capcityAcc?.From?.$numberDecimal,
                  to: capcityAcc?.To?.$numberDecimal,
                },
                UnreviwedPRRisk: {
                  risk: UnreviewedPR?.RiskCategory,
                  from: UnreviewedPR?.From?.$numberDecimal,
                  to: UnreviewedPR?.To?.$numberDecimal,
                },
                codeChurnData: {
                  risk: CodeChurn?.RiskCategory,
                  from: CodeChurn?.From?.$numberDecimal,
                  to: CodeChurn?.To?.$numberDecimal,
                },
                CommitWithPRRisk: {
                  risk: Commitwithpullrequest?.RiskCategory,
                  from: Commitwithpullrequest?.From?.$numberDecimal,
                  to: Commitwithpullrequest?.To?.$numberDecimal,
                },
                commitWithoutTicketRef: {
                  risk: commitWithoutTicketRef?.RiskCategory,
                  from: commitWithoutTicketRef?.From?.$numberDecimal,
                  to: commitWithoutTicketRef?.To?.$numberDecimal,
                },
                FlowEfficiencyRisk: {
                  risk: FlowEfficiencyRisk?.RiskCategory,
                  from: FlowEfficiencyRisk?.From?.$numberDecimal,
                  to: FlowEfficiencyRisk?.To?.$numberDecimal,
                },
              }}
              setChildViewAll={setChildViewAll}
              flowEfficiency={singleResourceFlowEfficiency}
              trendItem={(name) => {
                resourceTrend(name);
              }}
              lacgacy={headerData?.legacyRefactor}
              numberOfStoryPoint={avgStoryPoint}
            />
            {/* <Grid item xs={6}>
              <GraphCard
                title={"Maker"}

              >
                <WorkAlocationChart data={
                  [
                    {
                      "title": "Meeting",
                      "value": "46.52 %"
                    },

                    {
                      "title": "Project Discussion",
                      "value": "15.35 %"
                    }
                  ]
                } name="maker" />
              </GraphCard>
            </Grid> */}
            <Card className="resourceStyling paginationStyling customCard">
              <Template
                isDate={state.startDate && state.endDate ? false : true}
                isItration={state.startDate && state.endDate ? false : true}
                rangePickerValue={rangePickerValue}
                setRangePickerValue={setRangePickerValue}
                Metric={Metric}
                metricValue={metricValue}
                setMetricValue={setMetricValue}
                dropdownProjectList={[]}
                setTitleID={""}
                projectId={""}
                moduleDetailData={singleResourceInfo}
                materialTableColums={materialTableColums}
                isProject={false}
                tableOption={tableOption}
                isMetricValue={false}
                kpiName={kpiName}
              />
            </Card>
            <FormControlLabel
              control={<Switch />}
              label={showHideTrendButton ? "Show Trend" : "Hide Trend"}
              onClick={() => setShowHideTrendButton(!showHideTrendButton)}
              labelPlacement="start"
            />
            {!showHideTrendButton && (
              <Card className="resourceStyling paginationStyling customCard mt-5">
                <Graph
                  state={state}
                  classes={classes}
                  resourceId={resourceId}
                  rangePickerValue={rangePickerValue}
                  iterationValueGlobal={iterationMap[iterationValueGlobal]}
                  allMetrics={allMetrics}
                />
              </Card>
            )}
            {resourceTrendName && (
              <>
                {[
                  ...trendAndIssue,
                  "Resource Average Flow Efficiency",
                  "Resource Code Churn",
                ].includes(resourceTrendName) && (
                  <Card style={{ marginTop: 20 }}>
                    <Trend
                      issueType={resourceTrendName}
                      projectId={projectId}
                      resourceName={state?.resourceName}
                    />
                  </Card>
                )}
                {[...trendAndIssue].includes(resourceTrendName) && (
                  <Card style={{ marginTop: 20 }}>
                    <IssueLevel
                      issueType={resourceTrendName}
                      projectId={projectId}
                      statusList={
                        ["Resource Unreviewed PR"].includes(resourceTrendName)
                          ? statuslist
                          : []
                      }
                      autoScroll={[...trendAndIssue].includes(
                        resourceTrendName
                      )}
                      subStatus={
                        ["Resource Unreviewed PR"].includes(resourceTrendName)
                          ? selectedSubStatus
                          : []
                      }
                      isDate={
                        [...trendAndIssue].includes(resourceTrendName)
                          ? true
                          : false
                      }
                      resourceName={state?.resourceName}
                    />
                  </Card>
                )}
              </>
            )}
          </>
        );
      case 1:
        return (
          <InvestmentProfile
            // epic={epicData?.url.replace(/^\?/, "&")}
            project={projectId}
            resourceName={state?.resourceName}
          />
        );
      case 2:
        return <EffortAllocation />;
      default:
        break;
    }
  };

  return (
    <>
      <BreadCrumb
        backHandler={() => {
          history.goBack();
        }}
        list={[
          "Release",
          `${state?.projectName}`,
          state?.teamName,
          "Contributor",
        ]}
        active={`${state?.resourceName}`}
      />
      <Box mb={4}>
        <Paper>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
          >
            <Tab label="Resource" />
            <Tab
              label={
                <Box display={"flex"}>
                  Investment Distribution&nbsp;
                  <span
                    onClick={() => {
                      setIsDefinaionPopup(true);
                      setDefinationKPI("Investment profiles");
                    }}
                  >
                    <InfoIcon style={{ color: "gray" }} />
                  </span>
                </Box>
              }
            />
            <Tab
              label={
                <Box display={"flex"}>
                  Effort allocation&nbsp;
                  <span
                    onClick={() => {
                      setIsDefinaionPopup(true);
                      setDefinationKPI("Allocation");
                    }}
                  >
                    <InfoIcon style={{ color: "gray" }} />
                  </span>
                </Box>
              }
            />
          </Tabs>
        </Paper>
      </Box>
      <Box display={"flex"}>
        {value === 0 && (
          <EpicDropDown epicDropDownHandler={epicDropDownHandler} />
        )}
        {value === 0 && (
          <>
            {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
            <div style={{ marginTop: -8 }}>
              <SelectField
                input={
                  <Button
                    onClick={() => setOpenRange(true)}
                    style={{
                      border: "1px solid #b7b7b7",
                      width: "230px",
                      textTransform: "capitalize",
                      padding: "5px",
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingLeft: "14px ",
                      paddingRight: "11px",
                      justifyContent: "space-between",
                      borderRadius: "5px",
                      height: "54px",
                    }}
                    endIcon={<DateRangeOutlinedIcon />}
                  >
                    {getSelectedDate(date)}
                  </Button>
                }
                onClick={() => setOpenRange(true)}
              />
              <RangeSelector
                open={openRange}
                setOpen={setOpenRange}
                pickerHandler={(val) => setDate(val)}
                value={date}
                isNextDate={false}
              />
            </div>
          </>
        )}
      </Box>
      {getComponent(value)}
    </>
  );
};

export default SingleResoureceMetricInfo;

const HeaderTitle = ({ lablel, definationPopupHandler }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>{lablel} </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => definationPopupHandler(lablel)}
      >
        <InfoIcon style={{ color: "rgb(203 196 196)" }} fontSize="small" />
      </div>
    </div>
  );
};
