import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HealthStatus from "../../../../../components/helthStatus";
import CommonTable from "../../../../../components/commonTable";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { addDays, format, differenceInDays } from "date-fns";
import RangeSelector from "../../../../../components/rangeSelector";
import {
  datePayload,
  getSelectedDate,
} from "../../../../../utils/moduleTabApi";
import SelectField from "../../../../../components/select/selectField";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import instance from "../../../../../utils/axiosHelper";
import ExecutionStatus from "../../health/execution/ececutionStatus";
import { GlobalData } from "../../../../../context/globalData";
// import { GlobalData } from "../../../context/globalData";
import { config } from "../../../../../Config/permission";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CancelIcon from "@mui/icons-material/Cancel";
import AdsClickIcon from "@mui/icons-material/AdsClick";

const StatusTable = () => {
  const {
    projectRangePickerValue,
    setProjectRangePickerValue,
    setDeliveryTracker,
  } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [openRange, setOpenRange] = useState(false);
  const [InfoCard, setInfoCard] = useState();
  const [TableData, setTableData] = useState([]);
  const [TableDataFiltered, setTableDataFiltered] = useState([]);
  const [tableFilterCount, setTableFilterCount] = useState({});
  const [showOverAllPopup, setShowOverAllPopup] = useState(false);
  const [overAllData, setOverAllData] = useState({});
  const [ProbabilityDateAndTime, setProbabilityDateAndTime] = useState({});
  const { projectId } = useParams();
  const statusMenu = [
    "Life-time Progress",
    "Overall Contributor",
    "Overall FTE",
  ];

  useEffect(() => {
    setProjectRangePickerValue(date);
  }, [date]);
  const history = useHistory();
  const getHealthInfo = () => {
    const dateUrl = datePayload(date);
    instance
      .get(`/api/v2/ph/overall/${projectId}?&${dateUrl}`)
      .then((response) => {
        setInfoCard(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const getHealhTable = () => {
    const dateUrl = datePayload(date);
    instance
      .get(`/api/v2/ph/deliverables/${projectId}?&${dateUrl}`)
      .then((response) => {
        setTableData(response?.data);
        setTableDataFiltered(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    getHealthInfo();
    getHealhTable();
  }, [date]);

  const showOverData = (data) => {
    console.log(data, "data");
    setShowOverAllPopup(true);
    setOverAllData(data);

    const startDate = new Date(data?.startDate);

    const endDate = new Date(data?.targetDate);

    // Calculate the difference in milliseconds
    const diffInMs = endDate - startDate;

    // Convert milliseconds to hours
    const diffInHours = diffInMs / (1000 * 60 * 60);

    const newValue = 100 - data?.OverallPrediction;

    const predVal = (newValue / 100) * diffInHours;

    const accualNumberOfDay = (predVal / 8).toFixed();

    const newForecastDate = addDays(
      new Date(data?.targetDate),
      accualNumberOfDay
    );

    // New Forecast Date
    const formatedDate = format(newForecastDate, "dd-MM-yyyy");

    const targetDate = new Date(data?.targetDate);
    const newForeCast = new Date(newForecastDate);

    console.log(targetDate, "targetDate:");
    console.log(newForeCast, "newForeCast:");

    const statusDate = differenceInDays(newForeCast, targetDate);
    console.log("statusDate", statusDate);

    // if (statusDate > 0) {
    //   console.log("The number is positive");
    // } else if (statusDate < 0) {
    //   console.log("The number is negative");
    // }

    setProbabilityDateAndTime({
      foreCastDate: formatedDate,
      statuDelay: differenceInDays(newForeCast, targetDate),
    });
  };

  const [columns] = useState([
    {
      title: "Epic Id",
      field: "key",
      render: (row) => (
        <a href={row?.link} target="_blank" rel="noreferrer">
          {row?.key}
        </a>
      ),
    },
    {
      title: "Epic Url",
      field: "key",
      render: (row) => (
        <>
          {row?.status === "Done" ? (
            <Box color={"gray"} sx={{ cursor: "not-allowed" }}>
              {config.name === "aih" ? "Ticket" : "Epic"} Url{" "}
            </Box>
          ) : (
            <Box
              color={"#0d6efd"}
              onClick={() =>
                history.push({
                  pathname: `/project/${projectId}/healthExecution/${row?.key}`,
                  state: row,
                  date: date,
                })
              }
              sx={{ cursor: "pointer", textDecoration: "underline" }}
            >
              {config.name === "aih" ? "Ticket" : "Epic"} Url{" "}
              <OpenInNewIcon
                style={{ color: "#0d6efd", margin: "0px 2px" }}
                fontSize="small"
              />
            </Box>
          )}
        </>
      ),
    },
    {
      title: "Epic Name",
      field: "Description",
      render: (row) => (
        <div
          className="d-inline-block text-truncate"
          style={{ maxWidth: "150px" }}
        >
          {row?.Description}
        </div>
      ),
    },
    {
      title: "Component Name",
      field: "Component",
    },
    {
      title: `Overall Scope (${config.type})`,
      field: "scope",
    },
    {
      title: "Dev Attention",
      field: "DevAttention",
      render: (row) => (
        <>{row?.DevAttention[0]?.progressPercentage.toFixed()} %</>
      ),
    },
    {
      title: "Velocity",
      field: "velocity",
      render: (row) => row?.velocity.toFixed(2),
    },
    {
      title: "No of Contributors",
      field: "contributors",
    },
    {
      title: "Priority",
      field: "priority",
      render: (row) => (row?.priority === 0 ? "Not Assigned" : row?.priority),
    },
    {
      title: "Progress (%)",
      field: "progress",
      render: (row) => (
        <>
          {row?.progress.toFixed()}%
          <LinearProgress
            variant="determinate"
            value={row?.progress.toFixed()}
            style={{
              color: "#39f5bd",
              backgroundColor: "lightgray",
              height: "6px",
            }}
          />
        </>
      ),
    },
    {
      title: "FTE (Person-months)",
      field: "fte",
      render: (row) => <>{row?.fte.toFixed(2)}</>,
    },
    {
      title: "Target Date",
      field: "targetDate",
      render: (row) =>
        row?.targetDate === null
          ? "Not Set"
          : format(new Date(row?.targetDate), "dd-MMM-yyyy"),
    },
    {
      title: "Probability Of Completion (%)",
      field: "OverallPrediction",
      render: (row) => (
        <>
          {row?.status === "Done" ? (
            <div className="cursorPointer">-</div>
          ) : (
            <div onClick={() => showOverData(row)} className="cursorPointer">
              {row?.OverallPrediction}
              <AdsClickIcon style={{ padding: "0px 3px", color: "blue" }} />
            </div>
          )}
        </>
      ),
    },
    // {
    //   title: "Forecast",
    //   field: "expectedDate",
    //   render: (row) =>
    //     row?.expectedDate === null
    //       ? "Not Set"
    //       : format(new Date(row?.expectedDate), "dd-MMM-yyyy"),
    // },
    // {
    //   title: "Status",
    //   field: "status",
    //   render: (row) => (
    //     <ExecutionStatus status={row?.status}>{row?.status}</ExecutionStatus>
    //   ),
    // },
  ]);
  const TitleNAme = (
    <>
      <span className="fs-5">
        {`Deliverables in-Progress (${TableData?.length || 0}) `}
      </span>
      <span className="text-muted fs-6">
        ( {getSelectedDate(date)} )
        {/* ({format(new Date(getSelectedDate(date).split(" to ")[0]), "MMM yyyy")}{" "}
        to{" "}
        {format(new Date(getSelectedDate(date).split(" to ")[1]), "MMM yyyy")}) */}
      </span>
    </>
  );

  // Filter of Deliverables in-Progress
  const DeliverablesRisk = (filter1, filter2, filter3) => {
    if (filter1 === "All") {
      setTableData(TableDataFiltered);
      return;
    }
    const DeliverablesFilter = TableDataFiltered?.filter((item, index) => {
      return [filter1, filter2, filter3]?.some((filter) =>
        item?.status?.includes(filter)
      );
    });
    setTableData(DeliverablesFilter);
  };

  // Count of Deliverables in-Progress
  useEffect(() => {
    const counts = {
      high: 0,
      medium: 0,
      low: 0,
    };

    TableDataFiltered?.forEach((item) => {
      if (
        ["delayed", "Slipping"].some((filter) => item?.status?.includes(filter))
      ) {
        counts.high += 1;
      } else if (
        ["To do", "On hold", "In Progress"].some((filter) =>
          item?.status?.includes(filter)
        )
      ) {
        counts.medium += 1;
      } else if (
        ["Done", "On Time", "Open"].some((filter) =>
          item?.status?.includes(filter)
        )
      ) {
        counts.low += 1;
      }
    });

    setTableFilterCount(counts);
  }, [TableDataFiltered]);

  useEffect(() => {
    let statusAndPer = {};
    const totalScore =
      ((tableFilterCount.high + tableFilterCount.medium) /
        TableDataFiltered.length) *
      100;

    const totalOfDeliveryTracker = totalScore;

    if (totalOfDeliveryTracker <= 30) {
      statusAndPer.status = "BAD";
      statusAndPer.score = totalScore;
    } else if (totalOfDeliveryTracker >= 30 && totalOfDeliveryTracker <= 70) {
      statusAndPer.status = "AVERAGE";
      statusAndPer.score = totalScore;
    } else if (totalOfDeliveryTracker > 70) {
      statusAndPer.status = "GOOD";
      statusAndPer.score = totalScore;
    }

    setDeliveryTracker(statusAndPer);
  }, [TableDataFiltered, tableFilterCount]);

  return (
    <div>
      <Card className="p-3 mb-3 border">
        <Box mb={1} display={"flex"} justifyContent={"flex-end"}>
          <SelectField
            input={
              <Button
                onClick={() => setOpenRange(true)}
                style={{
                  border: "1px solid #b7b7b7",
                  width: "230px",
                  textTransform: "capitalize",
                  padding: "5px",
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingLeft: "14px ",
                  paddingRight: "11px",
                  justifyContent: "space-between",
                  borderRadius: "5px",
                  height: "54px",
                  color: "black",
                }}
                endIcon={<DateRangeOutlinedIcon />}
              >
                {getSelectedDate(date)}
              </Button>
            }
            onClick={() => setOpenRange(true)}
          />
          <RangeSelector
            open={openRange}
            setOpen={setOpenRange}
            pickerHandler={(val) => setDate(val)}
            value={date}
            isNextDate={false}
          />
        </Box>
        <Grid container spacing={1}>
          {statusMenu?.map((e) => (
            <Grid item xs={4} key={e}>
              <Card className="border">
                <Box px={3} textAlign={"center"} py={1}>
                  {e === "Life-time Progress" && (
                    <>
                      <Box>
                        <h5>{InfoCard?.LifeTimeProgress?.toFixed()} %</h5>
                        <Box my={1}>
                          <HealthStatus status={"Good"}></HealthStatus>
                        </Box>
                      </Box>
                    </>
                  )}
                  {e === "Overall Contributor" && (
                    <>
                      <Box>
                        <h5>{InfoCard?.OverallContributor}</h5>
                        <div>
                          <PeopleAltIcon
                            style={{ color: "#9ed368", margin: "5px 0px" }}
                          />
                        </div>
                      </Box>
                    </>
                  )}
                  {e === "Overall FTE" && (
                    <>
                      <Box>
                        <h5>
                          {InfoCard?.OverallFte.toFixed()} (Person-months)
                        </h5>
                        <div>
                          <PersonIcon
                            style={{ color: "#9ed368", margin: "5px 0px" }}
                          />
                        </div>
                      </Box>
                    </>
                  )}
                  {e}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
      <Card className="border position-relative">
        <Box className="position-absolute riskButtons">
          <button onClick={() => DeliverablesRisk("All")}>See All</button>
          <button onClick={() => DeliverablesRisk("delayed", "Slipping")}>
            <FiberManualRecordIcon sx={{ color: "red" }} /> High Risk (
            {tableFilterCount?.high})
          </button>
          <button
            onClick={() => DeliverablesRisk("To do", "On hold", "In Progress")}
          >
            <FiberManualRecordIcon sx={{ color: "yellow" }} /> Medium Risk (
            {tableFilterCount?.medium})
          </button>
          {/* <button
          onClick={() => DeliverablesRisk("Done", "On Time", "Open")}
          >
            <FiberManualRecordIcon sx={{ color: "green" }} /> Low Risk (
            {tableFilterCount?.low})
          </button> */}
        </Box>
        <CommonTable
          columns={columns}
          data={TableData}
          options={{
            sorting: true,
            search: true,
            scrollX: true,
          }}
          title={TitleNAme}
        />
      </Card>

      <Dialog open={showOverAllPopup} fullWidth>
        <div className="d-flex justify-content-between p-2">
          <strong>Overall Prediction</strong>
          <div
            onClick={() => setShowOverAllPopup(false)}
            className="cursorPointer"
          >
            <CancelIcon />
          </div>
        </div>
        <Box display={"flex"} justifyContent={"space-evenly"}>
          <b>Forecast Date Is: {ProbabilityDateAndTime?.foreCastDate}</b>
          <b>
            {ProbabilityDateAndTime?.statuDelay > 0 ? (
              <div className="text-danger">
                {`Status: Delayed ${ProbabilityDateAndTime?.statuDelay} Days`}
              </div>
            ) : (
              <div className={"text-success"}>Status: On Time</div>
            )}
          </b>
        </Box>

        <div class="container">
          <div className="row gy-2 m-2">
            <h5 className="text-center">
              <u>Key Parameters Taken</u>
            </h5>
            {/* <div className="col-6"> */}
            <div className="">
              {/* Multiple Factor Prediction:{" "} */}
              Technical risk (Software, hardware and Networks):{" "}
              {overAllData?.MultiplefactorPrediction} %
            </div>
            <div className="">
              {/* Performance Prediction: {overAllData?.PerformancePrediction} */}
              Performance rate of allocated developers:{" "}
              {overAllData?.PerformancePrediction} %
            </div>
            <div className="">
              {/* Probability Forth: {overAllData?.ProbabilityForth?.toFixed(2)} */}
              Average Developers Performance Index:{" "}
              {overAllData?.ProbabilityForth?.toFixed(2)}
            </div>
            <div className="">
              {/* Resource Prediction: {overAllData?.ResourcePrediction} */}
              Resource fulfillment: {overAllData?.ResourcePrediction} %
            </div>
            <div className="">
              {/* Task Prediction: {overAllData?.TaskPrediction} */}
              Task Complexity: {overAllData?.TaskPrediction} %
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default StatusTable;
