import { Box, Dialog } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";

const BestPractices = ({ onClose, open }) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <div>
      {" "}
      <Dialog open={open} sx={{ Padding: "0px 10px" }}>
        <Box p={2} className="d-flex justify-content-between">
          <strong>Best Practices</strong>
          <div onClick={() => handleClose()} className="cursorPointer">
            <CancelIcon />
          </div>
        </Box>
        <Box mx={2}>
          <ul type="disk" className="p-4">
            <li style={{ textAlign: "justify", marginBottom: "8px" }}>
              If the deliverable has just been initiated and it’s flagged as
              priority, then all three vectors will work; Add people, reduce
              scope or improve developer attention time.
            </li>
            <li style={{ textAlign: "justify", marginBottom: "8px" }}>
              If the deliverable is already in progress stage, best option is
              improving attention focus on developers already assigned to this
              task fullowed by reducing scope and adding new people.
            </li>
            <li style={{ textAlign: "justify", marginBottom: "8px" }}>
              If the deliverable work is near completion, cutting scope is the
              best option unless stakeholders agree that pushing timelines is
              viable. Leads and project managers will need to have a
              conversation with the team at this point to understand what the
              remaining pieces of work are and whether they offer enough value
              to be worth holding up the deliverable release.
            </li>
          </ul>
        </Box>
      </Dialog>
    </div>
  );
};

export default BestPractices;
